$(document).ready(function () {
  // Enable sortng fields
  $('#template_fields').sortable({
    axis: 'y',
    update: function () {
      return $.post($(this).data('update-url'), $(this).sortable('serialize'));
    },
  });

  // Reset all dynamic fields
  $(document).on(
    'change',
    '#temp_gross_ctc, #temp_no_of_days_payable',
    function () {
      $('.temp_field_of_earning').each(function (i, obj) {
        $(obj).val('');
      });
      $('.temp_field_of_deduction').each(function (i, obj) {
        $(obj).val('');
      });
      $('.temp_field_of_reimbursement').each(function (i, obj) {
        $(obj).val('');
      });
    }
  );

  // Salary calculation
  $(document).on(
    'change',
    '#temp_gross_ctc, #temp_no_of_days_payable, .temp_field_of_earning, .temp_field_of_deduction, .temp_field_of_reimbursement',
    function () {
      workDaysInMon = parseFloat(30);
      ctc = parseFloat($('#temp_gross_ctc').val());
      payableDays = parseFloat($('#temp_no_of_days_payable').val());

      // STEP1: Calculate and Set Salary earned
      salaryEarned = parseInt((ctc * payableDays) / workDaysInMon);
      $('#temp_salary_earned').val(salaryEarned);

      // STEP2: Calculate earnings

      // STEP2.1: Calulate and set Basic amount
      var basicSalary = 0;
      var basicElem = $('#temp_earning_basic_salary');
      if (basicElem.length) {
        basicSalary = parseFloat(basicElem.val());
        var percentage = parseFloat(basicElem.data('percentage'));
        if (isNaN(basicSalary)) {
          basicSalary = parseInt((salaryEarned * percentage) / 100);
        }
        basicElem.val(basicSalary);
      }

      // STEP2.2: Calulate and set HRA
      var earnHra = 0;
      var hraElem = $('#temp_earning_hra');
      if (hraElem.length) {
        earnHra = parseFloat(hraElem.val());
        var percentage = parseFloat(hraElem.data('percentage'));
        if (isNaN(earnHra)) {
          earnHra = parseInt((basicSalary * percentage) / 100);
        }
        hraElem.val(earnHra);
      }

      // STEP2.3: Calulate and set Conveyance allowance
      var convAllow = 0;
      var convElem = $('#temp_earning_conveyance_allowance');
      if (convElem.length) {
        convAllow = parseFloat(convElem.val());
        var maxVal = parseFloat(convElem.data('max-value'));
        if (isNaN(convAllow)) {
          convAllow = salaryEarned - basicSalary - earnHra;
          if (convAllow > maxVal) {
            convAllow = maxVal;
          }
        }
        convElem.val(convAllow);
      }

      // STEP2.4: Calulate and set Medical Reimbursement
      var medicalRe = 0;
      var medicalElem = $('#temp_earning_medical_reimbursement');
      if (medicalElem.length) {
        medicalRe = parseFloat(medicalElem.val());
        var maxVal = parseFloat(medicalElem.data('max-value'));
        if (isNaN(medicalRe)) {
          medicalRe = salaryEarned - basicSalary - earnHra - convAllow;
          if (medicalRe > maxVal) {
            medicalRe = maxVal;
          }
        }
        medicalElem.val(medicalRe);
      }

      // STEP2.5: Calulate and set Special allowance
      var splAllow = 0;
      var slpElem = $('#temp_earning_special_allowance');
      if (slpElem.length) {
        splAllow = parseFloat(slpElem.val());
        var minVal = parseFloat(slpElem.data('min-value'));
        if (isNaN(splAllow)) {
          splAllow =
            salaryEarned - basicSalary - earnHra - convAllow - medicalRe;
          if (splAllow <= minVal) {
            splAllow = minVal;
          }
        }
        slpElem.val(splAllow);
      }

      // STEP2.7: Set Total Earning
      var totalEarning = 0;
      $('.temp_field_of_earning').each(function (i, obj) {
        var ded = parseFloat($(obj).val());
        totalEarning += ded;
      });
      $('#temp_total_earning').val(totalEarning);
      if (salaryEarned == totalEarning) {
        $('#temp_total_earning').removeClass('invalid');
        $('#temp_total_earning').addClass('valid');
      } else {
        $('#temp_total_earning').removeClass('valid');
        $('#temp_total_earning').addClass('invalid');
      }

      // STEP3: Calculate deduction
      var totalDeduction = 0;
      $('.temp_field_of_deduction').each(function (i, obj) {
        var ded = parseFloat($(obj).val());
        if (!isNaN(ded)) {
          totalDeduction += ded;
        }
      });
      $('#temp_total_deduction').val(totalDeduction);

      // STEP4: Calculate reimbursement
      var totalRem = 0;
      $('.temp_field_of_reimbursement').each(function (i, obj) {
        var rem = parseFloat($(obj).val());
        if (!isNaN(rem)) {
          totalRem += rem;
        }
      });
      $('#temp_total_reimbursement').val(totalRem);

      // STEP5: Calculate net salary
      netSal = parseInt(totalEarning - totalDeduction + totalRem);
      $('#temp_net_salary_payable').val(netSal);
    }
  );
});
