$(document).ready(function() {
  $('.kye-form-field-type').trigger('change');
  $(document).on('change', '.kye-form-field-type', function(event) {
    var selectedFieldType = $(this).val();
    var selectOptionsField = $(this).closest('.row').find('#add-meta-data-field');
    var metaDataFields = $(this).closest('.row').find('.meta-data-field');
   

    if (selectedFieldType !=='select') {
      selectOptionsField.addClass('hidden');
      metaDataFields.addClass('hidden');
    } else {
      metaDataFields.removeClass('hidden');
      selectOptionsField.removeClass('hidden');
    }
  });
});



$(document).on('click', '.remove-meta-data-field', function(e) {
  e.preventDefault();
  $(this).closest('.meta-data-field').remove();
});

$(document).on('click', '#add-meta-data-field', function(e) {
  e.preventDefault();
  var container = $(this).next('.meta-data-fields')
  var template = $(this).data('metaoption')
  container.append(template);
});