initMap = function(mapContainer, addressInput, latitudeInput, longitudeInput, addressOutput) {
  let map;
  let marker;

  function marker_event(marker, addressInput) {
    google.maps.event.addListener(marker, 'dragend', function () {
      const { lat, lng } = marker.getPosition().toJSON();
      console.log(`Dragged to Latitude: ${lat}, Longitude: ${lng}`);
      latitudeInput.value = lat;
      longitudeInput.value = lng;

      const location = new google.maps.LatLng(lat, lng);

      reverseGeocode(location)
        .then(results => {
          const newAddress = results[0]?.formatted_address || '';
          setAddress(newAddress, addressInput, addressOutput);
        })
        .catch(error => {
          console.error('Reverse geocoding failed:', error);
        });
    });
  }

  function setAddress(address, addressInput, addressOutput) {
    addressInput.value = address;
    if (addressOutput) {
      addressOutput.value = address;
    }
  }

  function initializeMap(lat, lng, zoomLevel = 12) {
    map = new google.maps.Map(mapContainer, {
      center: { lat: lat, lng: lng },
      zoom: zoomLevel,
      zoomControl: false,
    });
    marker = new google.maps.Marker({
      map: map,
      position: { lat: lat, lng: lng },
      draggable: true,
    });
    marker_event(marker, addressInput);
  }

  const savedLat = parseFloat(latitudeInput.value);
  const savedLng = parseFloat(longitudeInput.value);

  if (!isNaN(savedLat) && !isNaN(savedLng)) {
    initializeMap(savedLat, savedLng);
  } else if ("geolocation" in navigator) {
    getCurrentPosition()
      .then(position => {
        const { latitude, longitude } = position.coords;
        initializeMap(latitude, longitude);
      })
      .catch(error => {
        console.error('Error getting current position:', error);
        initializeMap(22.563, 88.359, 8); // Default location if geolocation fails
      });
  } else {
    console.error('Geolocation is not supported');
    initializeMap(22.563, 88.359, 8); // Default location if geolocation is not supported
  }

  const center = { lat: 22.664192, lng: 88.605469 };
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };

  const options = {
    bounds: defaultBounds,
    componentRestrictions: { country: "in" },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
  };

  const autocomplete = new google.maps.places.Autocomplete(addressInput, options);
  autocomplete.addListener('place_changed', onPlaceChanged);

  function setMapLocation(location, zoomLevel) {
    map.panTo(location);
    map.setZoom(zoomLevel);
    if (marker) {
      marker.setPosition(location);
    } else {
      marker = new google.maps.Marker({
        map: map,
        position: location,
        draggable: true,
      });
      marker_event(marker, addressInput);
    }
  }

  function onPlaceChanged() {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      const location = place.geometry.location;
      latitudeInput.value = location.lat();
      longitudeInput.value = location.lng();
      setMapLocation(location, 12);
      setAddress(addressInput.value, addressInput, addressOutput);
    } else {
      alert('Geocoding failed: No geometry for the selected place');
    }
  }

  function getCurrentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  function reverseGeocode(location) {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'location': location }, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(new Error('Geocoding failed: ' + status));
        }
      });
    });
  }
};

function loadGoogleMapsScript() {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyByyzXZqoZ2NEN2LCGK4cc7nNi9iW5h6fk&libraries=places&callback=loadMap`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      console.log('Google Maps API loaded successfully');
      resolve('Script loaded successfully');
    };

    script.onerror = (error) => {
      console.error('Error loading Google Maps API:', error);
      reject('Script loading failed');
    };

    document.head.appendChild(script);
  });
}

// loadGoogleMapsScript()
//   .then(result => {
//     loadMap();
//   })
//   .catch(error => {
//     console.error('Promise rejected:', error);
//   });

loadMap = function () {
    const mapContainers = document.querySelectorAll('.map-container');

    mapContainers.forEach(container => {
      const mapType = container.getAttribute('data-map-type');
  
      if (mapType === 'permanent_address') {
        setTimeout(() => {
          initMap(
            container,
            document.getElementById('map_employee_address'),
            document.getElementById('employee_home_latitude'),
            document.getElementById('employee_home_longitude'),
            document.getElementById('employee_address')
          );
        }, 2000);
      } else if (mapType === 'additional_address') {
        setTimeout(() => {
          initMap(
            container,
            document.getElementById('employee_additional_address'),
            document.getElementById('additional_employee_lat'),
            document.getElementById('additional_employee_lng'),
            document.getElementById('additional_employee_address')
          );
        }, 2000);
      } else if (mapType === 'work_location') {
        setTimeout(() => {
          initMap(
            container,
            document.getElementById('work_location_address'),
            document.getElementById('work_location_latitude'),
            document.getElementById('work_location_longitude'),
            document.getElementById('work_location_address')
          );
        }, 2000);
      } else if (mapType === 'profile_permanent_address') {
        setTimeout(() => {
          initMap(
            container,
            document.getElementById('map_profile_address'),
            document.getElementById('profile_location_latitude'),
            document.getElementById('profile_location_longitude'),
            document.getElementById('employee_address')
          );
        }, 2000);
      }
    });
  }
  
window.loadGoogleMapsScript = loadGoogleMapsScript
window.generalInitMap = initMap



// initMap = function(mapContainer, addressInput, latitudeInput, longitudeInput, addressOutput) {
//   let map;
//   let marker;

//   function marker_event(marker, addressInput) {
//     google.maps.event.addListener(marker, 'dragend', function () {
//       const { lat, lng } = marker.getPosition().toJSON();
//       console.log(`Dragged to Latitude: ${lat}, Longitude: ${lng}`);
//       latitudeInput.value = lat;
//       longitudeInput.value = lng;

//       const location = new google.maps.LatLng(lat, lng);

//       reverseGeocode(location)
//         .then(results => {
//           const newAddress = results[0]?.formatted_address || '';
//           setAddress(newAddress, addressInput, addressOutput);
//         })
//         .catch(error => {
//           console.error('Reverse geocoding failed:', error);
//         });
//     });
//   }

//   function setAddress(address, addressInput, addressOutput) {
//     addressInput.value = address;
//     if (addressOutput) {
//       addressOutput.value = address;
//     }
//   }

//   map = '';
//   if (latitudeInput && latitudeInput.value && longitudeInput && longitudeInput.value) {
//     map = new google.maps.Map(mapContainer, {
//       center: { lat: parseFloat(latitudeInput.value), lng: parseFloat(longitudeInput.value) },
//       zoom: 12,
//       zoomControl: false,
//     });
//     marker = new google.maps.Marker({
//       map: map,
//       position: { lat: parseFloat(latitudeInput.value), lng: parseFloat(longitudeInput.value) },
//       draggable: true,
//     });
//     marker_event(marker, addressInput);
//   } else {
//     map = new google.maps.Map(mapContainer, {
//       center: { lat: 22.563, lng: 88.359 },
//       zoom: 8,
//       zoomControl: false,
//     });
//   }

//   if ("geolocation" in navigator) {
//     getCurrentPosition()
//       .then(position => {
//         const { latitude, longitude } = position.coords;
//         const userLocation = new google.maps.LatLng(latitude, longitude);
//         setMapLocation(userLocation, 12);
//       })
//       .catch(error => {
//         console.error('Error getting current position:', error);
//       });
//   } else {
//     console.error('Geolocation is not supported');
//   }

//   const center = { lat: 22.664192, lng: 88.605469 };
//   const defaultBounds = {
//     north: center.lat + 0.1,
//     south: center.lat - 0.1,
//     east: center.lng + 0.1,
//     west: center.lng - 0.1,
//   };

//   const options = {
//     bounds: defaultBounds,
//     componentRestrictions: { country: "in" },
//     fields: ["address_components", "geometry", "icon", "name"],
//     strictBounds: false,
//   };

//   const autocomplete = new google.maps.places.Autocomplete(addressInput, options);
//   autocomplete.addListener('place_changed', onPlaceChanged);

//   function setMapLocation(location, zoomLevel) {
//     map.panTo(location);
//     map.setZoom(zoomLevel);
//     if (marker) {
//       marker.setPosition(location);
//     } else {
//       marker = new google.maps.Marker({
//         map: map,
//         position: location,
//         draggable: true,
//       });
//       marker_event(marker, addressInput);
//     }
//   }

//   function onPlaceChanged() {
//     const place = autocomplete.getPlace();
//     if (place.geometry) {
//       const location = place.geometry.location;
//       latitudeInput.value = location.lat();
//       longitudeInput.value = location.lng();
//       setMapLocation(location, 12);
//       setAddress(addressInput.value, addressInput, addressOutput);
//     } else {
//       alert('Geocoding failed: No geometry for the selected place');
//     }
//   }

//   function getCurrentPosition() {
//     return new Promise((resolve, reject) => {
//       navigator.geolocation.getCurrentPosition(resolve, reject);
//     });
//   }

//   function reverseGeocode(location) {
//     return new Promise((resolve, reject) => {
//       const geocoder = new google.maps.Geocoder();
//       geocoder.geocode({ 'location': location }, (results, status) => {
//         if (status === 'OK') {
//           resolve(results);
//         } else {
//           reject(new Error('Geocoding failed: ' + status));
//         }
//       });
//     });
//   }
// };

// function loadGoogleMapsScript() {
//   return new Promise((resolve, reject) => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyByyzXZqoZ2NEN2LCGK4cc7nNi9iW5h6fk&libraries=places&callback=loadMap`;
//     script.async = true;
//     script.defer = true;

//     script.onload = () => {
//       console.log('Google Maps API loaded successfully');
//       resolve('Script loaded successfully');
//     };

//     script.onerror = (error) => {
//       console.error('Error loading Google Maps API:', error);
//       reject('Script loading failed');
//     };

//     document.head.appendChild(script);
//   });
// }

// // loadGoogleMapsScript()
// //   .then(result => {
// //     loadMap();
// //   })
// //   .catch(error => {
// //     console.error('Promise rejected:', error);
// //   });

// loadMap = function () {
//     const mapContainers = document.querySelectorAll('.map-container');

//     mapContainers.forEach(container => {
//       const mapType = container.getAttribute('data-map-type');
  
//       if (mapType === 'permanent_address') {
//         setTimeout(() => {
//           initMap(
//             container,
//             document.getElementById('map_employee_address'),
//             document.getElementById('employee_home_latitude'),
//             document.getElementById('employee_home_longitude'),
//             document.getElementById('employee_address')
//           );
//         }, 2000);
//       } else if (mapType === 'additional_address') {
//         setTimeout(() => {
//           initMap(
//             container,
//             document.getElementById('employee_additional_address'),
//             document.getElementById('additional_employee_lat'),
//             document.getElementById('additional_employee_lng'),
//             document.getElementById('additional_employee_address')
//           );
//         }, 2000);
//       } else if (mapType === 'work_location') {
//         setTimeout(() => {
//           initMap(
//             container,
//             document.getElementById('work_location_address'),
//             document.getElementById('work_location_latitude'),
//             document.getElementById('work_location_longitude'),
//             document.getElementById('work_location_address')
//           );
//         }, 2000);
//       } else if (mapType === 'profile_permanent_address') {
//         setTimeout(() => {
//           initMap(
//             container,
//             document.getElementById('map_profile_address'),
//             document.getElementById('profile_location_latitude'),
//             document.getElementById('profile_location_longitude'),
//             document.getElementById('employee_address')
//           );
//         }, 2000);
//       }
//     });
//   }
  
// window.loadGoogleMapsScript = loadGoogleMapsScript