$(document).on('change', '.earning-field, .deduction-field, .reimbursement-field', function() {
  let totalEarning = 0;
  let totalDeduction = 0;
  let totalReimbursement = 0;

  $('.earning-field').each(function() {
    totalEarning += parseFloat($(this).val()) || 0;
  });
  $('#total_earning').val(totalEarning.toFixed(2));

  $('.deduction-field').each(function() {
    totalDeduction += parseFloat($(this).val()) || 0;
  });
  $('#total_deduction').val(totalDeduction.toFixed(2));

  $('.reimbursement-field').each(function() {
    totalReimbursement += parseFloat($(this).val()) || 0;
  });
  $('#total_reimbursement').val(totalReimbursement.toFixed(2));

  let netPay = totalEarning - totalDeduction + totalReimbursement;
  $('#net_pay').val(netPay.toFixed(2));
});

$(document).on('change', "#salary", function () {
    let salary = parseFloat($(this).val()) || 0;

    let basic_salary = 0;
    let hra = 0;
    let conveyance_allowance = 0;
    let medical_reimbursement = 0;
    let special_allowance = 0;

    let totalEarning = 0;
    let totalDeduction = parseFloat($('#total_deduction').val()) || 0;
    let totalReimbursement = parseFloat($('#total_reimbursement').val()) || 0;

    let basic_salary_field = $('#earning_basic_salary');
    if (basic_salary_field.length) {
        let basic_salary_percentage = parseFloat(basic_salary_field.attr('data-percentage')) || 0;
        basic_salary = salary * basic_salary_percentage;
        basic_salary_field.val(basic_salary.toFixed(2));
        totalEarning += basic_salary;
    }

    let hra_field = $('#earning_hra');
    if (hra_field.length) {
        let hra_percentage = parseFloat(hra_field.attr('data-percentage')) || 0;
        hra = basic_salary * hra_percentage;
        hra_field.val(hra.toFixed(2));
        totalEarning += hra;
    }

    let conveyance_allowance_field = $('#earning_conveyance_allowance');
    if (conveyance_allowance_field.length) {
      let conveyance_allowance_max_value = parseFloat(conveyance_allowance_field.attr('data-maxvalue')) || 0;
      conveyance_allowance = Math.min(salary - (basic_salary + hra),conveyance_allowance_max_value);
      conveyance_allowance_field.val(conveyance_allowance.toFixed(2));
      totalEarning += conveyance_allowance;
    }

    let medical_reimbursement_field = $('#earning_medical_reimbursement');
    if (medical_reimbursement_field.length) {
      let medical_reimbursement_max_value = parseFloat(medical_reimbursement_field.attr('data-maxvalue')) || 0;
      medical_reimbursement = Math.min(salary - (basic_salary + hra + conveyance_allowance),medical_reimbursement_max_value);
      medical_reimbursement_field.val(medical_reimbursement.toFixed(2));
      totalEarning += medical_reimbursement;
    }

    let special_allowance_field = $('#earning_special_allowance');
    if (special_allowance_field.length) {
      special_allowance = salary - (basic_salary + hra + conveyance_allowance + medical_reimbursement);
      special_allowance_field.val(special_allowance.toFixed(2));
      totalEarning += special_allowance;
    }

    $('#total_earning').val(totalEarning.toFixed(2));

    let netPay = totalEarning - totalDeduction + totalReimbursement;
    $('#net_pay').val(netPay.toFixed(2));
});

$(document).on('change', ".ot_hrs_field", function (){

});