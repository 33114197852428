
async function getLocation() {
    $('.preloader-wrapper').addClass('active')
    $('.location_inactive').removeClass('hidden');
    $('.location_inactive_error').html("Fetching location of Device....")
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else { 
        $('.preloader-wrapper').removeClass('active')
        alert ("Geolocation is not supported by this browser.");
    }
}

function showPosition(position) {
    var latitude = position.coords.latitude
    var longitude = position.coords.longitude
    $("#hidden_latitude").val(latitude);
    $("#hidden_longitude").val(longitude);
    $("#attendance-submit-button").prop("disabled", false);
}

function showError(error) {
    var message = 'There is some error for fetching location of Device.'
    switch(error.code) {
      case error.PERMISSION_DENIED:
        message = "User denied the request for Geolocation.";
        break;
      case error.POSITION_UNAVAILABLE:
        message = "Location information is unavailable.";
        break;
      case error.TIMEOUT:
        message = "The request to get user location timed out.";
        break;
      case error.UNKNOWN_ERROR:
        message = "An unknown error occurred.";
        break;
    }
    alert ("Your are not allowed location permission");
    $('.location_inactive').removeClass('hidden');
    $('.preloader-wrapper').removeClass('active');
  }

$(document).ready(function () {
    

    $('#leave_application_employee_id').on('change', function(){
        $.ajax({
            url: '/leave_applications/new.js?selected_id='+$(this).val(),
            type: 'GET'
        })
    })
})

$(document).ready(function() {
  var submitButton = $('#attendance-submit-button');
  var dataThroughWeb = submitButton.data('through-web');
  
  if (dataThroughWeb == '0') {
    submitButton.prop("disabled", true);
    submitButton.attr("title", "Webcheckin is not permitted");
  }
});


window.getLocation = getLocation


$(document).ready(function() {
  function fetchLocation(callback) {
    var static_lat = 22.8787;
    var static_lng = 88.0143;
    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        callback(lat, lng);
      }, function(error) {
        console.error('Error getting geolocation:', error);
        callback(static_lat, static_lng);
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
      callback(static_lat, static_lng);
    }
  }

  // Function to handle check-in action
  $('#checkInBtn').click(function(e) {
    e.preventDefault();
    fetchLocation(function(lat, lng) {
      $.ajax({
        url: '/member/attendances',
        method: 'POST',
        dataType: 'script',
        data: { attendance: { login_latitude: lat, login_longitude: lng }},
        success: function(response) {
          console.log('Checked in successfully!', response);
          // Optionally handle success response
        },
        error: function(xhr, status, error) {
          console.error('Error checking in:', error);
          // Optionally handle error response
        }
      });
    });
  });

  // Function to handle check-out action
  $('#checkOutBtn').click(function(e) {
    e.preventDefault();
    var attendanceId = $(this).data('id');
    fetchLocation(function(lat, lng) {
      $.ajax({
        url: '/member/attendances/' + attendanceId, // Adjust URL to include attendanceId
        method: 'PUT',
        dataType: 'script',
        data: { attendance: { login_latitude: lat, login_longitude: lng }},
        success: function(response) {
          console.log('Checked out successfully!', response);
          // Optionally handle success response
        },
        error: function(xhr, status, error) {
          console.error('Error checking out:', error);
          // Optionally handle error response
        }
      });
    });
  });
});