$(document).ready(function() {
  $(document).on('click', '.toggle_employee_salary', function() {
    $(".employee_salary").toggleClass("green darken-1");
    var buttonText = $(this).text() === "Show Salary (Gross CTC / month)" ? "Hide Salary (Gross CTC / month)" : "Show Salary (Gross CTC / month)";
    $(this).text(buttonText);
  });
  $(document).on('change', '#onboarding_form input[type="checkbox"]', function() {
    $.ajax({
      type: 'PUT',
      url: $('#onboarding_form').attr('action'),
      data: $('#onboarding_form').serialize(),
      dataType: 'script',
      success: function() {
        console.log('Form submitted successfully!');
      },
      error: function() {
        console.error('Error submitting the form.');
      }
    });
  });
  $(document).on('change', '.has_attendance_config_switch', function(){
    if($(this).is(':checked')){
      $('.switch_toggle_target').removeClass('hidden')
    }else{
      $('.switch_toggle_target').addClass('hidden')
    }
  })
  $(document).on('click', '#empSettingTab', function(){
    $('#active_button').addClass('hidden')
  })
  $(document).on('click', '#empDevicesTab, #empDetailsTab, #empDocTab', function(){
    $('#active_button').removeClass('hidden')
  })

  $(document).on('click', '.address_model_trigger', function(){
    default_data = $(this).data()
    $('#generic-modal-content').html('<div class="modal-content"><div class="modal-header"><div class="flex-row flex-a-c"><div class="pr-4 flex-row flex-a-f"><div><strong class="display-block text-overflow-ellipsis font1-2">Select Address on map</strong></div></div><div class="flexgrow"></div><div><a class="modal-close btn-floating right white z-depth-0" href="#!"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-22 h-22 stroke-slate ml8 mt5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></a></div></div></div><div class="input-field col l6 s12 m0"><input id="modal_address" placeholder="search your address here" type="text" name="modal_address"></div><div class="mt10" id="model-map-container" style="height: 420px; border: 1px solid rgb(223, 223, 223); border-radius: 8px;"></div></div><div class="modal-footer"><a class="btn btn-minimal btn-slate darken-1 mr8 modal-close" data-remote="true" href="#!">Ok</a></div>');
    var elem = document.getElementById('generic-modal');
    var instance = M.Modal.getInstance(elem);
    instance.open();
    var container = document.getElementById('model-map-container')
    setTimeout(() => {
      generalInitMap(
        container,
        document.getElementById('modal_address'),
        document.getElementById('employee_home_latitude'),
        document.getElementById('employee_home_longitude'),
        document.getElementById('employee_address')
      );
    }, 500);
  })

  $(document).on('click', '.additional_address_model_trigger', function(){
    var $this = $(this)
    var lat_element = $this.closest('.row').find('#additional_employee_lat')[0]
    var lng_element = $this.closest('.row').find('#additional_employee_lng')[0]
    $('#generic-modal-content').html('<div class="modal-content"><div class="modal-header"><div class="flex-row flex-a-c"><div class="pr-4 flex-row flex-a-f"><div><strong class="display-block text-overflow-ellipsis font1-2">Select Address on map</strong></div></div><div class="flexgrow"></div><div><a class="modal-close btn-floating right white z-depth-0" href="#!"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-22 h-22 stroke-slate ml8 mt5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></a></div></div></div><div class="input-field col l6 s12 m0"><input id="modal_address" placeholder="search your address here" type="text" name="modal_address"></div><div class="mt10" id="model-map-container" style="height: 420px; border: 1px solid rgb(223, 223, 223); border-radius: 8px;"></div></div><div class="modal-footer"><a class="btn btn-minimal btn-slate darken-1 mr8 modal-close" data-remote="true" href="#!">Ok</a></div>');
    var elem = document.getElementById('generic-modal');
    var instance = M.Modal.getInstance(elem);
    instance.open();
    var container = document.getElementById('model-map-container')
    setTimeout(() => {
      generalInitMap(
        container,
        document.getElementById('modal_address'),
        lat_element,
        lng_element,
        $this[0]
      );
    }, 500);
  })



  // For employee onboarding data approval
  $(document).on('click', '.form-submit-on-click', function() {
    if (confirm("Are you sure?")){
      var formId = $(this).data('attribute');
      var form = $("#" + formId);
      var action = $(this).data('action');
      $('input[name="action_type"]').val(action);
      $.ajax({
          type: 'PUT',
          url: form.attr('action'),
          data: form.serialize(),
          dataType: 'script',
          success: function() {
              console.log('Form submitted successfully!');
          },
          error: function() {
              console.error('Error submitting the form.');
          }
      });
    }
  });

  // for employee onboading satage form submit
  $(document).ready(function() {
		$(document).on('click', '[data-action="submit-form"]', function(event) {
			$.ajax({
				type: 'POST',
				url: $('.edit_employee').attr('action'),
				data: $('.edit_employee').serialize(),
				dataType: 'script',
			});
		});
	});
});

$(document).on('click', 'input:radio[name=kye_form_rejection]', function(){
  if (confirm("Are you sure?")){
    var val = $(this).val()
    var id = $('#employee_id').val()
    var remark = $('#form_rejection_remark').val()
    var kye_form = $('#kye_form_id').val()
    $.ajax({
      type: "PUT",
      url: "/employees/" + id + "/process_onboarding_form?action_type=" + val + '&remarks=' + remark + '&kye_form_id=' + kye_form + '&form_type=dynamic'
    })
  }
  return false
})

$(document).on('click', '.static-reject-button', function() {
    var formId = $(this).data('formid');
    $('#static_kye_form_rejection_modal').find('#static_form_id').val(formId);
});

$(document).on('click', 'input:radio[name=static_kye_form_rejection]', function() {
  if (confirm("Are you sure?")) {
    var action = $(this).val();
    var form_id = $('#static_form_id').val();
    var form = $("#"+form_id);
    var remark = $('#static_form_rejection_remark').val();
    
    form.find('[name=action_type]').val(action);
    form.find('[name=remarks]').val(remark);
    form.find('[name=form_name]').val(remark);

    
    $.ajax({
      type: 'PUT',
      url: form.attr('action'),
      data: form.serialize(),
      dataType: 'script',
      success: function(response) {
      },
      error: function(xhr, status, error) {
       console.log(error);
       console.log(status);
      }
    });
  }
  return false;
});


document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll(".circular-progress").forEach(function(progressBar) {
    const progressValue = progressBar.querySelector(".percentage");
    let startValue = 0,
      endValue = Number(progressBar.getAttribute("data-percentage")),
      speed = 1, 
      progressColor = progressBar.getAttribute("data-progress-color");

    const progress = setInterval(() => {
      startValue += 1;

      if (startValue > endValue) {
        startValue = endValue;
      }

      progressValue.textContent = `${startValue}`;
      progressValue.style.color = `${progressColor}`;

      const degrees = startValue * 3.6;
      progressBar.style.background = `conic-gradient(${progressColor} ${degrees}deg, ${progressBar.getAttribute("data-bg-color")} 0deg)`;

      if (startValue >= endValue) {
        clearInterval(progress);
      }
    }, speed);
  });
});

$(document).ready(function() {
  function updateClock() {
      var now = moment();
      var timeString = now.format('hh:mm A');

      $('#minute-updating-clock').text(timeString);
  }

  if ($('#minute-updating-clock').length) {
      updateClock();
      setInterval(updateClock, 6000);
  }
});


// import moment from 'moment';

function calculateServiceSpan(joiningDate, currentDate) {
  const joinDate = moment(joiningDate);
  const now = moment(currentDate);

  const duration = moment.duration(now.diff(joinDate));

  const years = duration.years();
  const months = duration.months();
  const days = Math.max(duration.days(), 0);

  const date = [];

  if (years > 0) date.push(`${years} year${years > 1 ? 's' : ''}`);
  if (months > 0) date.push(`${months} month${months > 1 ? 's' : ''}`);
  if (days > 0) date.push(`${days} day${days > 1 ? 's' : ''}`);

  return date.length > 0 ? date.join(' ') : '-';
}


// Function to update the service span in the DOM
document.addEventListener('DOMContentLoaded', () => {
  const employeeRows = document.querySelectorAll('.employee-row');

  employeeRows.forEach(row => {
    const joiningDate = row.getAttribute('data-start-date');
    const currentDate = row.getAttribute('data-end-date');
    const serviceSpan = calculateServiceSpan(joiningDate, currentDate);

    const serviceSpanElement = row.querySelector('.service-span');
    if (serviceSpanElement) {
      serviceSpanElement.textContent = serviceSpan;
    }
  });
});

