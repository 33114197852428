$(document).ready(function () {
  // $('.show-subdomain').hide();

  define(['jquery', 'jquery-validation'], function ($) {
    $('#set_password_form').validate({
      rules: {
        'user[password]': { required: true, minlength: 6 },
        'user[password_confirmation]': {
          required: true,
          equalTo: '#user_password',
        },
      },
    });
  });

  $(document).on('keyup', '#tenant_name', function () {
    let name = this.value
    let parameterized_subdomain = string_parameterize(name)
    let trimmed_subdomain = trim_subdomain(parameterized_subdomain)
    $('#tenant_subdomain').val(trimmed_subdomain)
  });

  $(document).on('keyup', '#tenant_subdomain', function () {
    let regex = /^[a-z]+$/;
    let permitted_subdomain = this.value
    if (regex.test(permitted_subdomain) !== true) {
      permitted_subdomain = permitted_subdomain.replace(/[^a-z]+/, '');
    }
    this.value = trim_subdomain(permitted_subdomain);
  });



  $(document).on('change', '#tenant_subdomain', function () {
    console.log("Hi", this.value.length)
    // if (this.value.length > 15) {
    //   console.log("Lengtj ios: "+this.value.length)
    //   // If it is, truncate the value to the first 15 characters
    //   $(this).val(this.value.slice(0, 15));
    // }
  });

  $(document).on('change', "#tenant_subscription_plan_type", function(){
    var val = $(this).val()
    if(val == 1){
      $('.subscribtion_plan_info_recharge').removeClass('hidden');
      $('.subscribtion_plan_info_complete').addClass('hidden');
    }else if(val == 2){
      $('.subscribtion_plan_info_recharge').addClass('hidden');
      $('.subscribtion_plan_info_complete').removeClass('hidden');
    }
  })

  $(document).on('click', 'input:radio[name=leave_applications_status]', function(){
    if (confirm("Are you sure?")){
      var val = $(this).val()
      var id = $('#leave_application_id').val()
      var remark = $('#rejection_remark').val()
      $.ajax({
        type: "PUT",
        url: "/leave_applications/" + id + "/update_state?type=" + val + '&remarks=' + remark
      })
    }
    return false
  })

  $(document).on('click', 'input:radio[name=member_leave_applications_status]', function(){
    if (confirm("Are you sure?")){
      var val = $(this).val()
      var id = $('#leave_application_id').val()
      var remark = $('#rejection_remark').val()
      $.ajax({
        type: "PUT",
        url: "/member/leave_applications/" + id + "/update_state?type=" + val + '&remarks=' + remark
      })
    }
    return false
  })

  function string_parameterize (str1) {
    return str1.toLowerCase().replace(/[^a-z0-9]+/g, '').replace(/[0-9]/g, '');
  };

  function trim_subdomain(string) {
    if (string.length > 20) {
      // If it is, truncate the value to the first 15 characters
      string = string.slice(0, 20);
    }
    return string
  }

});
