// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('jquery-ui');
require('jquery-validation');
require('packs/payslip');
require('packs/payslip_template');
require('packs/tenants');
require('packs/common');
require('packs/employee_attendance');
require('packs/map')
require('packs/employee')
require('packs/countdown_clock')
require('packs/dynamic_form')
require('packs/chart')
require('packs/payroll')
require('packs/salary_register')
import 'materialize-css/dist/js/materialize';
import $ from 'jquery';
import Rails from '@rails/ujs'; 
import intlTelInput from 'intl-tel-input';
import moment from 'moment';

global.Rails = Rails;
global.intlTelInput = intlTelInput;
global.$ = jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

import * as echarts from "echarts";
import "echarts/theme/dark";

window.echarts = echarts;
window.moment = moment