import { color } from "echarts";

document.addEventListener('DOMContentLoaded', function() {


  function createAttendanceChart(data, month) {
    // chart.hideLoading();
    var option = {
      backgroundColor: '#F7F7F8',
      borderRadius: 10,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: '15%',
        left: '4%',
        right: '4%',
        bottom: '2%',
        containLabel: true,
        height: '75%',
        borderRadius: [4, 4, 0, 0]
      },
      xAxis: [{
        type: 'category',
        data: data.map(data => data.date),
        axisLabel: {
          formatter: '{value}'
        },
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          lineStyle: {
            color: '#999'
          }
        }
      }],
      yAxis: [{
        type: 'value',
        name: 'Attendance (in %)',
        nameTextStyle: {
          fontSize: 14
        },
        axisLabel: {
          formatter: '{value}%'
        },
        axisLine: {
          lineStyle: {
            color: '#999'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: '#ddd'
          }
        }
      }],
      series: [{
          name: month,
          type: 'bar',
          barWidth: '50%',
          itemStyle: {
            color: '#0088fe',
            borderRadius: [2, 2, 0, 0]
          },
          data: data.map(data => data.percentage)
        }
      ]
    };
    chart.setOption(option);
  }
  if (document.getElementById('bar-chart-container')) {
    var data = JSON.parse(document.getElementById('bar-chart-container').getAttribute('data-records'));
    var month = (document.getElementById('bar-chart-container').getAttribute('data-month'));
    var chart = echarts.init(document.getElementById('bar-chart-container'));
    createAttendanceChart(data, month);
  }

  function createWorkLocationPieChart(data) {
    var option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical', // Orient the legend vertically
        right: '10%',       // Position the legend 10% from the right edge
        top: 25,       // Center the legend vertically
        formatter: function(name) {
          var value = data.find(item => item.name === name).value;
          return `${name}: ${value}`;
        },
        icon: 'circle',
        textStyle: {
          color: '#6C6C89',
          fontFamily: 'Inter, sans-serif' // Set the font family here
        },
      },
      title: {
        text: 'WORKING FROM',
        textStyle: {
          color: '#6C6C89',
          fontSize: '0.80rem',
          fontWeight: 500
        },
        top: 0, // Adjust the top value to position the title
        right: '15%' // Position the title in the center
      },
      color: ['#0088fe', '#ffbb27', '#ff6384'],
      // color: ["#1fc16b", "#7d52f4", "#fb3748"],
      series: [
        {
          name: 'Working from',
          type: 'pie',
          radius: ['60%', '90%'],
          center: ['20%', '50%'], // Position the pie chart in the center
          avoidLabelOverlap: false,
          padAngle: 2,
          itemStyle: {
            borderRadius: 3
          },
          label: {
            show: false
          },
          legend: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: data
        }
      ]
    };
    chart.setOption(option);
  }

  function resizeChart(chart) {
    if (chart) {
      chart.resize();
    }
  }
  if (document.getElementById('pie-chart-container')) {
    var dataRecords = JSON.parse(document.getElementById('pie-chart-container').getAttribute('data-records'));
    var data = [
      { value: dataRecords.wf_office, name: 'Office' },
      { value: dataRecords.wf_home, name: 'Home' },
      { value: dataRecords.wf_outside, name: 'Outside' }
    ];
    var chart = echarts.init(document.getElementById('pie-chart-container'));
    createWorkLocationPieChart(data);
  }


  function createMemberAttendancePieChart(data) {
    var option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical', // Orient the legend vertically
        right: '20%',       // Position the legend 10% from the right edge
        top: 25,       // Center the legend vertically
        formatter: function(name) {
          var value = data.find(item => item.name === name).value;
          return `${name}: ${value}`;
        },
        icon: 'circle',
        textStyle: {
          color: '#6C6C89',
          fontFamily: 'Inter, sans-serif' // Set the font family here
        },
      },
      title: {
        // text: 'WORKING FROM',
        textStyle: {
          color: '#6C6C89',
          fontSize: '0.80rem',
          fontWeight: 500
        },
        top: 0, // Adjust the top value to position the title
        right: '5%' // Position the title in the center
      },
      color: ['#CEEAD5', '#0DA5E9', '#ffbb27'],
      // color: ["#1fc16b", "#7d52f4", "#fb3748"],
      series: [
        {
          name: 'Working from',
          type: 'pie',
          radius: ['60%', '90%'],
          center: ['20%', '50%'], // Position the pie chart in the center
          avoidLabelOverlap: false,
          padAngle: 2,
          itemStyle: {
            borderRadius: 3
          },
          label: {
            show: false
          },
          legend: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: data
        }
      ]
    };
    chart.setOption(option);
  }


  if (document.getElementById('member-attendance-chart')) {
    var dataRecords = JSON.parse(document.getElementById('member-attendance-chart').getAttribute('data-records'));
    var data = [
      { value: dataRecords.present, name: 'Present' },
      { value: dataRecords.wf_home, name: 'Home' },
      { value: dataRecords.absent, name: 'Absent' }
    ];
    var chart = echarts.init(document.getElementById('member-attendance-chart'));
    createMemberAttendancePieChart(data);
  }
  window.addEventListener('resize', function() {
    resizeChart(chart); // Assuming myChart is your chart instance
  });
});
