$(document).ready(function () {

  // Submit the form on input change
  $(document).on('change', '.filter-form-input', function() {
    console.log("Helloooo")
    $(".filter-form").submit()
  })

  $(document).on('change', '.remote-filter-form-input', function () {
    // remote-filter-form
    nativeFormElement = $(this); 
    console.log(this.form)
    Rails.fire(this.form, 'submit'); 
  })

  update_working_days_field()
  $(document).on('change', "#pay_slip_employee_id, #pay_slip_month, #pay_slip_year, #pay_slip_no_of_days_payable", function(e) {
    //auto Fill employee sl
    var selected_month = $('#pay_slip_month').val()
    var selected_year = $('#pay_slip_year').val()
    var gross_ctc = $("#pay_slip_gross_ctc").val()
    $.ajax({
      url: "/employees/"+$('#pay_slip_employee_id').val()+".json?month="+selected_month+"&year="+selected_year, 
      success: function(result) {
        // console.log(result)
        if (result.employee?.salary && gross_ctc != result.employee?.salary) {
          $("#pay_slip_gross_ctc").val(result.employee?.salary).change()
        }
        if(result.unpaid_leaves){
          pre_val = $('#pay_slip_no_of_days_payable').val()
          $('#pay_slip_unpaid_leave').val(result.unpaid_leaves)
          $('#pay_slip_no_of_days_payable').val(pre_val - result.unpaid_leaves).change()
        }
      }
    });
  })

  // Reset all dynamic fields
  $(document).on(
    'change',
    '#pay_slip_gross_ctc, #pay_slip_no_of_days_payable',
    function () {
      $('.field_of_earning').each(function (i, obj) {
        $(obj).val('');
      });
      $('.field_of_deduction').each(function (i, obj) {
        $(obj).val('');
      });
      $('.field_of_reimbursement').each(function (i, obj) {
        $(obj).val('');
      });
    }
  );

  $('.custom-file-input-select').on('change', function(e) {
    var name;
    name = e.target.files[0].name;
    $('.custom-file-input').val(name);
  });

  var $fileInput = $('.file_input');
  var $droparea = $('.file_droparea');

  $fileInput.on('dragenter focus click', function () {
    $droparea.addClass('is-active');
  });

  $fileInput.on('dragleave blur drop', function () {
    $droparea.removeClass('is-active');
  });

  $fileInput.on('change', function (e) {
    var filesCount = $(this)[0].files.length;
    // name = $(this)[0].files[0].name
    var $textContainer = $(this).prev('.js-set-number');
    if (filesCount === 1) {
      $textContainer.text($(this).val().split('\\').pop());
      var name;
      name = e.target.files[0].name;
      $('.custom-file-input').val(name);
      }else {
        $textContainer.text(filesCount + ' uploaded files');
      }
    // $('.custom-file-input').val name
    });


  // Salary calculation
  $(document).on(
    'change',
    '#pay_slip_gross_ctc, #pay_slip_no_of_days_payable, .field_of_earning, .field_of_deduction, .field_of_reimbursement',
    function () {
      workDaysInMon = parseFloat($('#pay_slip_no_of_working_days').val());
      ctc = parseFloat($('#pay_slip_gross_ctc').val());
      payableDays = parseFloat($('#pay_slip_no_of_days_payable').val());

      // STEP1: Calculate and Set Salary earned
      salaryEarned = parseInt((ctc * payableDays) / workDaysInMon);
      $('#pay_slip_salary_earned').val(salaryEarned);

      // STEP2: Calculate earnings
      // STEP2.1: Calulate and set Basic amount
      var basicSalary = 0;
      var basicElem = $('#pay_slip_earnings_basic_salary');
      if (basicElem.length) {
        basicSalary = parseFloat(basicElem.val());
        var percentage = parseFloat(basicElem.data('percentage'));
        if (isNaN(basicSalary)) {
          basicSalary = parseInt((salaryEarned * percentage) / 100);
        }
        basicElem.val(basicSalary);
      }

      // var basicSalary = parseFloat($('#pay_slip_earnings_basic_salary').val());
      // if (isNaN(basicSalary)) {
      //   basicSalary = parseInt((salaryEarned * 40) / 100);
      // }
      // $('#pay_slip_earnings_basic_salary').val(basicSalary);

      // STEP2.2: Calulate and set HRA

      // STEP2.2: Calulate and set HRA
      var earnHra = 0;
      var hraElem = $('#pay_slip_earnings_hra');
      if (hraElem.length) {
        earnHra = parseFloat(hraElem.val());
        var percentage = parseFloat(hraElem.data('percentage'));
        if (isNaN(earnHra)) {
          earnHra = parseInt((basicSalary * percentage) / 100);
        }
        hraElem.val(earnHra);
      }

      // var earnHra = parseFloat($('#pay_slip_earnings_hra').val());
      // if (isNaN(earnHra)) {
      //   earnHra = parseInt((basicSalary * 40) / 100);
      // }
      // $('#pay_slip_earnings_hra').val(earnHra);

      // STEP2.3: Calulate and set Conveyance allowance

      var convAllow = 0;
      var convElem = $('#pay_slip_earnings_conveyance_allowance');
      if (convElem.length) {
        convAllow = parseFloat(convElem.val());
        var maxVal = parseFloat(convElem.data('max-value'));
        if (isNaN(convAllow)) {
          convAllow = salaryEarned - basicSalary - earnHra;
          if (convAllow > maxVal) {
            convAllow = maxVal;
          }
        }
        convElem.val(convAllow);
      }

      // var convAllow = parseFloat(
      //   $('#pay_slip_earnings_conveyance_allowance').val()
      // );
      // if (isNaN(convAllow)) {
      //   convAllow = salaryEarned - basicSalary - earnHra;
      //   if (convAllow > 1600) {
      //     convAllow = 1600;
      //   }
      // }
      // $('#pay_slip_earnings_conveyance_allowance').val(convAllow);

      // STEP2.4: Calulate and set Medical Reimbursement

      var medicalRe = 0;
      var medicalElem = $('#pay_slip_earnings_medical_reimbursement');
      if (medicalElem.length) {
        medicalRe = parseFloat(medicalElem.val());
        var maxVal = parseFloat(medicalElem.data('max-value'));
        if (isNaN(medicalRe)) {
          medicalRe = salaryEarned - basicSalary - earnHra - convAllow;
          if (medicalRe > maxVal) {
            medicalRe = maxVal;
          }
        }
        medicalElem.val(medicalRe);
      }

      // var medicalRe = parseFloat(
      //   $('#pay_slip_earnings_medical_reimbursement').val()
      // );
      // if (isNaN(medicalRe)) {
      //   medicalRe = salaryEarned - basicSalary - earnHra - convAllow;
      //   if (medicalRe > 1250) {
      //     medicalRe = 1250;
      //   }
      // }
      // $('#pay_slip_earnings_medical_reimbursement').val(medicalRe);

      // STEP2.5: Calulate and set Special allowance

      var splAllow = 0;
      var slpElem = $('#pay_slip_earnings_special_allowance');
      if (slpElem.length) {
        splAllow = parseFloat(slpElem.val());
        var minVal = parseFloat(slpElem.data('min-value'));
        if (isNaN(splAllow)) {
          splAllow =
            salaryEarned - basicSalary - earnHra - convAllow - medicalRe;
          if (splAllow <= minVal) {
            splAllow = minVal;
          }
        }
        slpElem.val(splAllow);
      }

      // var splAllow = parseFloat(
      //   $('#pay_slip_earnings_special_allowance').val()
      // );
      // if (isNaN(splAllow)) {
      //   splAllow = salaryEarned - basicSalary - earnHra - convAllow - medicalRe;
      //   if (splAllow <= 0) {
      //     splAllow = 0;
      //   }
      // }
      // $('#pay_slip_earnings_special_allowance').val(splAllow);

      // STEP2.6: Check Bonus
      var bonus = parseFloat($('#pay_slip_bonus').val());
      if (isNaN(bonus)) {
        bonus = 0;
      }
      $('#pay_slip_bonus').val(bonus);
      // STEP2.7: Set Total Earning
      var totalEarning = 0;
      $('.field_of_earning').each(function (i, obj) {
        var ded = parseFloat($(obj).val());
        totalEarning += ded;
      });
      $('#pay_slip_total_earning').val(totalEarning);

      // STEP3: Calculate deduction
      var totalDeduction = 0;
      $('.field_of_deduction').each(function (i, obj) {
        var ded = parseFloat($(obj).val());
        if (!isNaN(ded)) {
          totalDeduction += ded;
        }
      });
      $('#pay_slip_total_deduction').val(totalDeduction);

      // STEP4: Calculate reimbursement
      var totalRem = 0;
      $('.field_of_reimbursement').each(function (i, obj) {
        var rem = parseFloat($(obj).val());
        if (!isNaN(rem)) {
          totalRem += rem;
        }
      });
      $('#pay_slip_total_reimbursement').val(totalRem);

      // STEP5: Calculate net salary
      netSal = parseInt(totalEarning - totalDeduction + totalRem);
      $('#pay_slip_net_salary_payable').val(netSal);
    }
  );

  $(document).on('change', '#pay_slip_month', update_working_days_field);
})

function update_working_days_field(){
  var allMonths, sMonth, thirtyDMon;
  sMonth = $('#pay_slip_month').val();
  thirtyDMon = {
    january: 31,
  };
  allMonths = {
    january: 31,
    february: 28,
    march: 31,
    april: 30,
    may: 31,
    june: 30,
    july: 31,
    august: 31,
    september: 30,
    october: 31,
    november: 30,
    december: 31,
  };
  $('#pay_slip_no_of_working_days').val(allMonths['' + sMonth]);
  $('#pay_slip_no_of_days_payable').val(allMonths['' + sMonth]);
}