document.addEventListener('DOMContentLoaded', function() {
  var countdownClocks = document.querySelectorAll('.countdown-clock');
  function updateCountdown(clock, time) {
      var minutes = Math.floor(time / 60);
      var seconds = time % 60;
      clock.innerText = minutes + 'm ' + seconds + 's';
      if (time <= 0) {
          clearInterval(clock.intervalId);
          clock.innerText = 'Expired';
      } else {
          time--;
      }
  }
  function startCountdown(clock, time) {
      clock.intervalId = setInterval(function() {
          updateCountdown(clock, time);
          if (time <= 0) {
              clearInterval(clock.intervalId);
          } else {
              time--;
          }
      }, 1000);
  }
  countdownClocks.forEach(function(clock) {
      var remainingTime = parseInt(clock.getAttribute('data-attribute'));
      if (!isNaN(remainingTime) && remainingTime > 0) {
          startCountdown(clock, remainingTime);
      }
  });
});